import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import CodeIcon from './../images/code.svg';
import HtmlIcon from './../images/html.svg';
import CssIcon from './../images/css.svg';
import GitIcon from './../images/git.svg';
import JsIcon from './../images/js.svg';
import ReactiIcon from './../images/reacti.svg';
import MaterialIcon from './../images/material.svg';
import GsapIcon from './../images/gsap.svg';
import InfinityIcon from './../images/infinity.svg';
// import { useMediaQuery } from 'react-responsive';
// import './home.scss';

export default function RightHome() {
    // const isDesktopScreen = useMediaQuery({ query: '(min-width: 768px)' })
    const codeRef = useRef(null);
    const html5Ref = useRef(null);
    const html5CircleRef = useRef(null);
    const css3Ref = useRef(null);
    const gitRef = useRef(null);
    const jsRef = useRef(null);
    const reactRef = useRef(null);
    const materialRef = useRef(null);
    const gsapRef = useRef(null);
    const infinityRef = useRef(null);

    useEffect(() => {
        const tlCode = gsap.timeline();
        tlCode.to(codeRef.current, {opacity: 1, scale: 1, duration: 1})
            .to(html5Ref.current, {opacity: 1, marginTop: 0, duration: 1, ease: 'bounce.out'})
            .to(html5CircleRef.current, {opacity: 1, scale: 1, transformOrigin: '50% 50%', yoyo: true, repeat: -1, duration: 1.25, ease: 'Power4.out'}, '+=1')
            .to(css3Ref.current, {opacity: 1, marginTop: 0, duration: 0.5}, '-=2.75')
            .to(css3Ref.current, {marginLeft: 0, duration: 0.2, ease: 'Power4.in'})
            .to(gitRef.current, {opacity: 1, scale: 1, duration: 1.05, ease: 'elastic.out(1.5, 0.5)'}, '-=0.3')
            .to(jsRef.current, {opacity: 1, marginLeft: 0, duration: 1.05, ease: 'Power4.out'}, '-=0.3')
            .to(reactRef.current, {opacity: 1, marginLeft: 0, duration: 1.05, ease: 'Power4.out'}, '-=1')
            .to(jsRef.current, {marginLeft: -80, duration: 0.25, ease: 'Power4.in'})
            .to(reactRef.current, {marginLeft: 80, duration: 0.25, ease: 'Power4.in'}, '-=0.1')
            .to(jsRef.current, {marginTop: 0, duration: 0.25, ease: 'Power4.in'})
            .to(reactRef.current, {marginTop: 0, duration: 0.25, ease: 'Power4.in'}, '-=0.2')
            .to(jsRef.current, {marginLeft: 0, duration: 0.95, ease: 'Power4.inOut'})
            .to(reactRef.current, {marginLeft: 0, duration: 0.95, ease: 'Power4.inOut'}, '-=0.9')
            .to(materialRef.current, {scale: 1, rotation: 1800, duration: 2, ease: 'Power4.inOut'}, '-=4')
            .to(gsapRef.current, {opacity: 1, marginLeft:0, duration: 2.25, ease: 'Power4.inOut'}, '+=1.15')
            .to(infinityRef.current, {opacity: 1, scale: 1, duration: 1.5, ease: 'Power4.inOut'}, '-=2.2');
            // .to(forwardRef.current, {opacity: 1, duration: 1, marginLeft: -160, delay: 1.5, ease: 'ease-in'}, 0.1)
            // .fromTo(forwardRef.current, { y:'0' }, { y:'-=6', duration: 0.015, repeat:5, yoyo:true, delay: -0.45, ease: 'sine.inOut' })
            // .to(forwardRef.current, {marginLeft: 0, duration: 0.55, delay: -0.475, ease: 'slow(0.9, 0.5, false)'})
            // .to(stopRef.current, {rotation: 720, duration: 7, ease: 'power4.easeInOut' }, '-=3.5')
            // .to(recRef.current, {opacity: 1, scale:1, duration: 1.5, ease: 'elastic.out(1.5, 0.4)' }, '-=3.5')
            // .to(recRef.current, {scale:0.9, yoyo:true, duration: 2, repeat: -1, ease: 'power4.out' }, '+=2')
            // .to(storybookRef.current, {opacity:1, marginTop:0, duration: 3.2, ease: 'power4.easeInOut' }, '-=8');
    });

    return (
        <div className='right-home'>
            <div className='first gsapCode' ref={codeRef}>
                <CodeIcon />
            </div>
            <div className='second'>
                <div className="gsapHtml5" ref={html5Ref}>
                    <HtmlIcon />
                </div>
                <div className='html-circle gsapHtml5Circle' ref={html5CircleRef}></div>
            </div>
            <div className='second2 gsapCss3' ref={css3Ref}>
                <CssIcon />
            </div>
            <div className='third gsapGit' ref={gitRef}>
                <GitIcon />
            </div>
            <div className='third2 gsapJs' ref={jsRef}>
                <JsIcon />
            </div>
            <div className='fourth'>
                <div className='gsapReact' ref={reactRef}>
                    <ReactiIcon />
                </div>
                <div className='react-circle'></div>
            </div>
            <div className='fifth'>
                <div className='gsapMaterial' ref={materialRef}>
                    <MaterialIcon />
                </div>
                <div className='material-circle'></div>
            </div>
            <div className='fifth2'>
                <div className='gsapGsap' ref={gsapRef}>
                    <GsapIcon />
                </div>
                <div className='gsap-circle'></div>
            </div>
            <div className='sixth'>
                <div className='gsapInfinity' ref={infinityRef}>
                    <InfinityIcon />
                </div>
                <div className='infinity-circle'></div>
            </div>
        </div>
    )
}
