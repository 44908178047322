import React from 'react';
// import CodeImage from '../components/code';
// import { useMediaQuery } from 'react-responsive';
import Nav from '../components/nav';
import LeftHome from '../components/left';
// import MobileMenu from '../components/mobilemenu';
import RightHome from '../components/right-home';
// import Terminal from '../components/terminal';
import './home.scss';

export default function Home() {
    // const isDesktopScreen = useMediaQuery({ query: '(min-width: 768px)' })
    return (
        <div>
            {/* {isDesktopScreen ? <Menu active='home' /> : <MobileMenu active='home' />} */}
            {/* {isDesktopScreen ? '' : <MobileMenu active='home' />} */}
            <Nav />
            <div className='home'>
                <LeftHome />
                <div className='center-home'>
                    <h1>designhorf</h1>
                    <h2 className='center-home-h2'>Product designer</h2>
                </div>
                <RightHome />
            </div>
            {/* <Terminal /> */}
        </div>
    )
}
