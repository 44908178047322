import React, { useRef, useEffect } from 'react';
import Triangle from './../images/triangle.svg';
import Ps from './../images/ps.svg';
import Sketch from './../images/sketch.svg';
import Invision from './../images/invision.svg';
import Figma from './../images/figma.svg';
import Framer from './../images/framer.svg';
import Rewind from './../images/rewind.svg';
// import Storybook from './../images/storybook.svg';
import Stop from './../images/stop.svg';
import Rec from './../images/rec.svg';
import { gsap } from 'gsap';
// import { useMediaQuery } from 'react-responsive';
// import './home.scss';

export default function LeftHome() {
    const playRef = useRef(null);
    const sketchRef = useRef(null);
    const psdRef = useRef(null);
    const circleRef = useRef(null);
    const invisionRef = useRef(null);
    const invisionCircleRef = useRef(null);
    const figmaRef = useRef(null);
    const forwardRef = useRef(null);
    const stopRef = useRef(null);
    // const storybookRef = useRef(null);
    const framerRef = useRef(null);
    const recRef = useRef(null);
    const recCircle = useRef(null);

    useEffect(() => {
        gsap.to(playRef.current, {scale:0.85, duration: 2.25, yoyo:true, repeat: -1, ease: 'expo.inOut'})
        gsap.to(sketchRef.current, {opacity: 1, duration: 2.1, marginTop: 0, ease: 'power4.out'});
        gsap.to(psdRef.current, {
            opacity: 1,
            duration: 1.45,
            delay: 0.5,
            marginLeft: 0,
            ease: 'power4.out'
        });
        gsap.to(circleRef.current, {
            opacity: 1,
            duration: 1.25,
            delay: 1.45,
            ease: 'power1.out',
        });
        gsap.to(framerRef.current, {
            opacity: 1,
            scale: 1,
            delay: 1.25,
            duration: 1.05,
            ease: 'elastic.out(1.5, 0.5)',
        });

        const tlInvisionCircle = gsap.timeline();
        tlInvisionCircle.to(invisionCircleRef.current, {opacity: 1, scale: 1, delay: 2, duration: 2.55, ease: 'elastic.out(1.5, 0.3)'})
        tlInvisionCircle.to(invisionCircleRef.current, {opacity: 0, scale: 2, duration: 4, repeat: -1, ease: 'power4.out'}, '-=1.25');

        const tlFigma = gsap.timeline();
        tlFigma.to(figmaRef.current, {opacity: 1, duration: 1, marginLeft: -80, delay: 2.75, ease: 'ease-in'})
            .to(forwardRef.current, {opacity: 1, duration: 1, marginLeft: -160, delay: 1.5, ease: 'ease-in'}, 0.1)
            .fromTo(forwardRef.current, { y:'0' }, { y:'-=6', duration: 0.015, repeat:5, yoyo:true, delay: -0.45, ease: 'sine.inOut' })
            .to(forwardRef.current, {marginLeft: 0, duration: 0.55, delay: -0.475, ease: 'slow(0.9, 0.5, false)'})
            .to(stopRef.current, {rotation: 720, duration: 7, ease: 'power4.easeInOut' }, '-=3.5')
            .to(recRef.current, {opacity: 1, scale:1, duration: 1.5, ease: 'elastic.out(1.5, 0.4)' }, '-=3.5')
            .to(recRef.current, {scale:0.9, yoyo:true, duration: 2, repeat: -1, ease: 'power4.out' }, '+=2')
            // .to(storybookRef.current, {opacity:1, marginTop:0, duration: 3.2, ease: 'power4.easeInOut' }, '-=8');
            .to(invisionRef.current, {opacity:1, marginTop:0, duration: 3.2, ease: 'power4.easeInOut' }, '-=8');
            // .to(recCircle.current, {opacity: 1, scale:1, duration: 2.25, repeat: -1, ease: 'power4.out' }, '-=7');
        // tlFigma.to(invisionCircleRef.current, {scale: 2, duration: 5, delay: 2, opacity: 0, repeat: -1, ease: 'elastic.out(1, 1)'}, 2);

        // timeline.to(invisionCircleRef.current, {
        //     opacity: 1,
        //     scale: 1,
        //     delay: 2,
        //     duration: 2.55,
        //     ease: 'elastic.out(1.5, 0.3)',
        // }.fromTo(invisionCircleRef2.current, {
        //         scale: 1,
        //     },
        //     {
        //         scale: 1.5,
        //         delay: 6,
        //         duration: 2.55,
        //         repeat: 5,
        //         ease: 'elastic.out(1.5, 0.3)',
        //     }
        // ));
        // gsap.fromTo(invisionCircleRef2.current, {
        //     scale: 1,
        // },
        // {
        //     scale: 1.5,
        //     delay: 6,
        //     duration: 2.55,
        //     repeat: 5,
        //     ease: 'elastic.out(1.5, 0.3)',
        // });
    }, []);
    // const isDesktopScreen = useMediaQuery({ query: '(min-width: 768px)' })
    return (
        <div className='left-home'>
            <div className='first gsapPlay' ref={playRef}><Triangle /></div>
            <div className='second gsapPs' ref={psdRef}><Ps /><div className='ps-circle gsapCircle' ref={circleRef}></div></div>
            <div className='third gsapSketch' ref={sketchRef}><Sketch /></div>
            <div className='fourth'>
                <div ref={framerRef} className='gsapInvison'>
                    <Framer />
                </div>
                <div className='invision-circle gsapInvisionCircle' ref={invisionCircleRef}></div>
            </div>
            <div className='fifth gsapFigma' ref={figmaRef}><Figma /></div>
            <div className='fifth2 gsapForward' ref={forwardRef}><Rewind /></div>
            <div className='sixth gsapStorybook' ref={invisionRef}><Invision /></div>
            <div className='seventh' ref={stopRef}><Stop /></div>
            <div className='seventh2'>
                <div ref={recRef} className='gsapRec'>
                    <Rec />
                </div>
                <div className='rec-circle gsapRecCircle' ref={recCircle}></div>
            </div>
        </div>
    )
}
